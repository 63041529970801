import React, { useState } from "react";
import { Button, Menu, Popover, Row, Col, message } from "antd";
import {
  SettingFilled,
  ControlOutlined,
  HistoryOutlined,
  PullRequestOutlined,
} from "@ant-design/icons";
import { MENU_TYPE } from "context/Constant";
import { Link } from "react-router-dom";
import GameServices from "services/GameServices";

const CardGame = ({
  item,
  openGame,
  isVisibled,
  onClickMenu,
  setCurrentGame,
}) => {
  //Popover
  const [openPopover, setOpenPopover] = useState(false);

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const menuItems = [
    getItem("ตั้งค่าเกม", MENU_TYPE.SETTING, <SettingFilled />),
    getItem("ล้างการตั้งค่า", MENU_TYPE.RESET_CONFIG, <ControlOutlined />),
    getItem("ล้างประวัติการเล่น", MENU_TYPE.RESET_HISTORY, <HistoryOutlined />),
  ];

  const handleOpenPopoverChange = (newOpen) => {
    setOpenPopover(newOpen);
  };

  const handleOpenPopoverAndsetgame = (data) => {
    setOpenPopover(true);
    setCurrentGame(data);
  };

  const handleSwitchGame = () => {
    GameServices.switchGame({
      gameId: item?.game_id,
      redirectUrl: "",
    }).then(() => {
      message.success("สลับเกมสำเร็จ กรุณารอสักครู่ !");
    });
  };

  return (
    <div className="card-game">
      <Link to={`/${item?.game_id}/index.html`} target="_blank" relative="path">
        <img
          // onClick={() => openGame(item?.game_id)}
          src={item?.game_image}
          style={{
            width: "100%",
            minHeight: "125px",
            cursor: "pointer",
            objectFit: "contain",
          }}
        />
      </Link>

      <div className="title-game">{item?.game_name}</div>
      {isVisibled && (
        <Row gutter={[24, 0]} style={{ marginTop: "5px" }}>
          <Col xs={6}>
            <Button
              type="primary"
              icon={<PullRequestOutlined />}
              style={{ borderColor: "#ead6d6" }}
              onClick={handleSwitchGame}
            />
          </Col>
          <Col xs={18}>
            <Popover
              placement="leftTop"
              className="transparent-popover"
              content={
                <Menu
                  onClick={onClickMenu}
                  style={{ width: 180 }}
                  theme="dark"
                  mode="vertical"
                  items={menuItems}
                />
              }
              trigger="click"
              open={openPopover}
              onOpenChange={handleOpenPopoverChange}
            ></Popover>
            <Button
              type="primary"
              className="button-icon-d-none-450-down"
              icon={<SettingFilled />}
              style={{
                width: "100%",
                background: "#e9961a",
                borderColor: "#ead6d6",
                textAlign: "center",
              }}
              onClick={() => handleOpenPopoverAndsetgame(item)}
            >
              ตั้งค่า
            </Button>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default CardGame;
