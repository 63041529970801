import React, { useEffect, useState } from "react";
import { Row, Col, Button, Modal } from "antd";
import { ClockCircleOutlined, CalendarOutlined } from "@ant-design/icons";
import AnnounceService from "services/AnnounceServer";
import { formatDateTime } from "utils/utility";

const ModalAnnou = ({ isShow, setIsShowMenuAnnou }) => {
  const [announceList, setAnnounceList] = useState([]);

  useEffect(() => {
    AnnounceService.getAnnouncement().then(({ data }) => {
      setAnnounceList(data.reverse());
    });
  }, []);

  return (
    <Modal
      className="ant-modal-dark"
      title={<div>ประกาศ</div>}
      open={isShow}
      onOk={() => setIsShowMenuAnnou(false)}
      onCancel={() => setIsShowMenuAnnou(false)}
      maskClosable={false}
      style={{ top: 40 }}
      footer={[
        <Row gutter={[8, 0]} key="ft">
          <Col span={24}>
            <Button
              style={{ height: "40px", background: "#cf2e2e" }}
              key="submit"
              type="primary"
              onClick={() => setIsShowMenuAnnou(false)}
            >
              ปิดหน้าต่างนี้
            </Button>
          </Col>
        </Row>
      ]}
    >
      <div style={{ padding: "10px 0px" }}>
        <Row
          gutter={[12, 16]}
          style={{ maxHeight: "600px", overflowY: "scroll" }}
        >
          {announceList.map((item) => {
            return (
              <Col xs={24} key={item?._id}>
                <div className="card-announcement">
                  <h4>{item?.title}</h4>
                  <div className="content">{item?.detail}</div>
                  <div
                    style={{
                      marginBottom: "15px",
                      textAlign: "center",
                      borderRadius: "1rem"
                    }}
                  >
                    <img
                      src={`https://assets.pgcrack.com/images/${item?.image}`}
                      alt="crack-img"
                      className="feed-img"
                    />
                  </div>

                  <div className="desc">
                    <CalendarOutlined style={{ margin: "0px 5px" }} />
                    <span>
                      {formatDateTime(item?.createdAt, "onlyDate")}
                    </span>{" "}
                    <ClockCircleOutlined style={{ margin: "0px 3px" }} />{" "}
                    <span>{formatDateTime(item?.createdAt, "onlyTime")}</span>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    </Modal>
  );
};

export default ModalAnnou;
