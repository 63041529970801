export const SPIN_TYPE = {
  SPIN: "spin",
  FREE: "free",
  FEATURE: "feature"
};

export const UPDATE_TYPE = {
  MINUS: "minus",
  PLUS: "plus"
};

export const MENU_TYPE = {
  SETTING: "GAME_SETTING",
  RESET_CONFIG: "RESET_GAME_CONFIG",
  RESET_HISTORY: "RESET_GAME_HISORY"
};
