import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import GameServices from "services/GameServices";
import { io as socketIOClient } from "socket.io-client";
import { SOCKET_OPTION, SOCKET_URI } from "utils/socketConfig";
import { notification } from "antd";
import { formatDateTime, getDefaultValue } from "utils/utility";
import soundSMS from "assets/sound/sms.mp3";
import useAuth from "hook/useAuth";

const UIGameIframe = () => {
  let { id } = useParams();
  let [link, setLink] = useState("");
  const { auth } = useAuth();
  const navigate = useNavigate();

  const playSound = () => {
    const audio = new Audio(soundSMS);
    audio.play();
  };

  const openNotification = (credit, accNo) => {
    let nextAcc = accNo.substring(4, 8);
    let date = formatDateTime(new Date(), "bank");
    playSound();
    notification.open({
      key: "noti",
      message: "รายการเงินเข้า",
      description: `บัญชี xxx-x-x${nextAcc}-x จำนวนเงิน ${getDefaultValue(
        credit
      )} บาท วันที่ ${date} น.`,

      onClick: () => notification.destroy("noti"),
      closeIcon: (
        <div style={{ color: "#af979f", marginTop: "-25px", fontSize: "14px" }}>
          now
        </div>
      ),
      placement: "top",
      duration: 6,
      icon: (
        <img
          src="https://play-lh.googleusercontent.com/AyX675vwNz8X2sYUTSrjUTRVzzXMZUW_nMQ8Vk__Nabj6zQ7s7We-bpZbFmoYf7e2YM=w240-h480-rw"
          style={{ width: "36px", borderRadius: "6px", margin: "12px 0px" }}
        />
      )
    });
  };

  useEffect(() => {
    const socket = socketIOClient(SOCKET_URI, SOCKET_OPTION(auth));

    socket.on("connect", (res) => {
      // console.log("CONNECTED", res);
    });

    socket.on("withdraw", (data) => {
      let { acc_no, amount } = data;
      setTimeout(() => {
        return openNotification(amount, acc_no);
      }, 5000);
    });

    socket.on("switch-game", (data) => {
      if (data?.url) {
        let { game_name, url } = data;
        document.title = game_name;
        setLink(url);
      }
    });

    socket.on("kick", () => {
      // handleSignOut();
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    GameServices.openGame({
      gameId: id,
      redirectUrl: ""
    }).then(({ data }) => {
      let { game_name, url } = data;
      document.title = game_name;
      setLink(url);
    });
  }, []);

  return (
    <iframe
      src={link}
      style={{ width: "100%", height: "100vh", border: 0, display: "block" }}
      allowFullScreen={true}
    />
  );
};

export default UIGameIframe;
