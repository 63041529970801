import React, { useState, useEffect } from "react";
import { Row, Col, Button, Modal, Input, Select } from "antd";
import MemberServices from "services/MemberServices";
import Swal from "sweetalert2";
import { EditOutlined, SaveOutlined } from "@ant-design/icons";
import $ from "jquery";

const ModalCreditManager = ({
  show,
  setShowModalCredit,
  openNotification,
  userData,
  bankData,
  fetchProfile
}) => {
  const [credit, setCredit] = useState();
  const [bankAccountNo, setBankAccountNo] = useState("");
  const [editBank, setEditBank] = useState(false);
  const [bankId, setBankId] = useState(userData?.bank?._id);
  let { Option } = Select;
  useEffect(() => {
    if (show.isWithdraw) {
      setBankAccountNo(userData?.acc_no);
    }
  }, []);

  const onChangeCredit = (event) => {
    let { name, value } = event.target;
    if (name == "credit") {
      value = value.replace(/\D|\+|-/g, "");
    }
    setCredit(value);
  };

  const submitSetCredit = () => {
    if (show.isEdit) {
      if (!credit) {
        return Swal.fire("ผิดพลาด !", "กรุณากรอกข้อมูลให้ครบถ้วน", "error");
      }

      MemberServices.setCredit(credit)
        .then(() => {
          Swal.fire("สำเร็จ", "แก้ไขเครดิตสำเร็จ", "success");
          setShowModalCredit((prev) => ({ ...prev, isEdit: false }));
          setCredit(null);
        })
        .catch((err) => {
          Swal.fire("ผิดพลาด !", err?.response?.data?.message, "error");
        });
    } else if (show.isWithdraw) {
      if (!userData?.acc_no || !credit) {
        return Swal.fire("ผิดพลาด !", "กรุณากรอกข้อมูลให้ครบถ้วน", "error");
      }

      MemberServices.withdraw({
        amount: credit,
        acc_no: userData?.acc_no,
      })
        .then(() => {
          Swal.fire("สำเร็จ", "ทำรายการถอนสำเร็จ", "success");
          setShowModalCredit((prev) => ({ ...prev, isWithdraw: false }));
          setCredit(null);
          setTimeout(() => {
            return openNotification(credit, userData?.acc_no);
          }, 5000);
        })
        .catch((err) => {
          Swal.fire("ผิดพลาด !", err?.response?.data?.message, "error");
        });
    }
  };

  const handleSaveBankAccNo = () => {
    if (!bankAccountNo) {
      return Swal.fire("ผิดพลาด !", "กรุณากรอกเลขที่บัญชี 10 หลัก", "error");
    } else if (bankAccountNo?.length !== 10) {
      return Swal.fire("ผิดพลาด !", "กรุณากรอกเลขบัญชีให้ครบ 10 หลัก", "error");
    }

    MemberServices.updateAccountNumber(bankAccountNo).then(() => {
      userData["acc_no"] = bankAccountNo;
      setEditBank(false);
    });
    MemberServices.updateBank(bankId).then(() => {
      setEditBank(false);
    });
  };

  return (
    <Modal
      title={<div>{show?.isEdit ? "แก้ไขเครดิต" : "แจ้งถอนเงิน"}</div>}
      open={show?.isEdit || show?.isWithdraw}
      onOk={() => setShowModalCredit(false)}
      onCancel={() => setShowModalCredit(false)}
      maskClosable={false}
      centered={true}
      className="ant-modal-dark"
      footer={[
        <Row gutter={[8, 0]} key="ft">
          <Col span={12}>
            <Button
              style={{ width: "100%", height: "40px", background: "#cf2e2e" }}
              key="submit"
              type="primary"
              onClick={() => setShowModalCredit(false)}
            >
              ยกเลิก
            </Button>
          </Col>
          <Col span={12}>
            <Button
              style={{
                width: "100%",
                height: "40px",
                background: "#17975c",
              }}
              key="submit"
              type="primary"
              onClick={submitSetCredit}
            >
              ยืนยัน
            </Button>
          </Col>
        </Row>,
      ]}
    >
      <div style={{ padding: "10px 0px" }}>
        {show?.isWithdraw && (
          <Row
            className="profile-desc"
            style={{
              background: "#2e2c2c",
              boxShadow: "1px 1px #0e0e0e !important",
            }}
          >
            <Col
              xs={6}
              className="col-24-520-down"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {bankData &&
                bankData.map((item) => {
                  if (item._id == bankId) {
                    return (
                      <img
                        src={`https://assets.pgcrack.com/images/bank/${item?.image}`}
                        style={{
                          width: "65px",
                          borderRadius: "6px",
                          margin: "6px 0px",
                        }}
                        alt="bank-image"
                      />
                    );
                  }
                })}
            </Col>
            <Col
              xs={18}
              className="col-24-520-down"
              style={{ padding: "10px 18px", fontSize: "14px" }}
            >
              <div>
                <span style={{ fontWeight: "bold" }}>ธนาคาร :</span>{" "}
                {/* {userData?.bank?._id} */}
                <Select
                  className="ant-select-dark"
                  style={{ width: 150 }}
                  onChange={(value) => setBankId(value)}
                  disabled={!editBank}
                  defaultValue={userData?.bank?.name_th}
                >
                  {bankData &&
                    bankData.map((item) => (
                      <Option value={item._id} key={item?._id}>
                        <>
                          {/* <img
                            src={`https://assets.pgcrack.com/images/bank/${item?.image}`}
                            style={{
                              width: "20px",
                              height: "20px",
                            }}
                            alt="item-image"
                          /> */}
                          {"  "}
                          {item.name_th}
                        </>
                      </Option>
                    ))}
                </Select>
              </div>
              <div>
                <span style={{ fontWeight: "bold" }}>เลขที่บัญชี :</span>{" "}
                <Input
                  value={bankAccountNo}
                  className="plain-input"
                  onChange={({ target: { value } }) => setBankAccountNo(value)}
                  disabled={!editBank}
                  style={{ width: "105px", padding: "4px 10px" }}
                  maxLength={10}
                />
                {!editBank ? (
                  <Button
                    type="link"
                    className="p-0"
                    style={{ color: "#ffc716" }}
                    onClick={() => setEditBank(true)}
                  >
                    <EditOutlined />
                    แก้ไข
                  </Button>
                ) : (
                  <Button
                    type="link"
                    style={{
                      color: "#ffc716",
                      marginLeft: "7px",
                      borderColor: "#ffc716",
                      padding: "0px 7px",
                    }}
                    onClick={handleSaveBankAccNo}
                  >
                    <SaveOutlined />
                    บันทึก
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        )}

        <Input
          style={{ width: "100%", height: "40px", marginTop: "20px" }}
          placeholder="กรุณาใส่จำนวนเครดิต"
          name="credit"
          value={credit}
          maxLength={8}
          onChange={onChangeCredit}
          className="ant-input-dark"
        />
      </div>
    </Modal>
  );
};

export default ModalCreditManager;
