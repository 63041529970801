import React, { useState, useEffect } from "react";
import { Row, Col, Button, Modal, Divider, Select } from "antd";
import { HistoryOutlined, SettingOutlined } from "@ant-design/icons";
import { getStorage, setStorage } from "utils/utility";
import GameServices from "services/GameServices";
import MemberServices from "services/MemberServices";
import Swal from "sweetalert2";

const ModalMenuSettings = ({ isShow, setIsShowMenuSettings, usLang }) => {
  const [loadings, setLoadings] = useState([false, false]);
  const [inGameLang, setIngameLang] = useState({});

  useEffect(() => {
    let langObj = { value: getStorage("igl") || usLang, title: "" };
    if (usLang === "eng") {
      langObj.title = "ภาษาอังกฤษ (ENG)";
    } else if (usLang === "th") {
      langObj.title = "ภาษาไทย (TH)";
    }
    setIngameLang(langObj);
  }, []);

  const enterLoading = (index) => {
    setLoadings((prevLoading) => {
      let nextLoading = [...prevLoading];
      nextLoading[index] = true;
      return nextLoading;
    });

    if (index == 0) {
      GameServices.resetAllGameHistory().then((res) => {
        setTimeout(() => {
          setLoadings((prevLoadings) => {
            const nextLoading = [...prevLoadings];
            nextLoading[index] = false;
            Swal.fire("สำเร็จ", "ล้างประวัติการเล่นเรียบร้อยแล้ว", "success");
            return nextLoading;
          });
        }, 1000);
      });
    } else {
      GameServices.resetAllGameConfig()
        .then((res) => {
          setTimeout(() => {
            setLoadings((prevLoading) => {
              const nextLoading = [...prevLoading];
              nextLoading[index] = false;
              Swal.fire("สำเร็จ", "รีเซ็ตการตั้งค่าเกมสำเร็จ", "success");
              return nextLoading;
            });
          }, 1000);
        })
        .catch((err) =>
          Swal.fire("ผิดพลาด !", err?.response?.data?.message, "error")
        );
    }
  };

  const handleChangeInGameLang = (event) => {
    MemberServices.updateInGameLanguage(event.value)
      .then(() => {
        setIngameLang(event.value);
        setStorage("igl", event.value);
      })
      .catch((err) => console.log(err?.response?.data?.message));
  };

  return (
    <Modal
      className="ant-modal-dark"
      title={<div>ตั้งค่าระบบ</div>}
      open={isShow}
      onOk={() => setIsShowMenuSettings(false)}
      onCancel={() => setIsShowMenuSettings(false)}
      maskClosable={false}
      centered={true}
      footer={[
        <Row gutter={[8, 0]} key="row">
          <Col span={24}>
            <Button
              style={{ height: "40px", background: "#cf2e2e" }}
              key="submit"
              type="primary"
              onClick={() => setIsShowMenuSettings(false)}
            >
              ปิดหน้าต่างนี้
            </Button>
          </Col>
        </Row>
      ]}
    >
      <div>
        {/* <Input
          style={{ width: "100%", height: "40px" }}
          placeholder="กรุณาใส่จำนวนเครดิต"
          name="credit"
          value={credit}
          onChange={onChangeCredit}
        /> */}
        <Divider className="ant-divider-dark" plain>
          ตั้งค่าเกมทั้งหมด
        </Divider>

        <Row gutter={[12, 16]}>
          <Col xs={12} className="col-24-520-down">
            <Button
              type="primary"
              icon={<HistoryOutlined />}
              loading={loadings[0]}
              onClick={() => enterLoading(0)}
              block
            >
              ล้างประวัติการเล่นทั้งหมด
            </Button>
          </Col>
          <Col xs={12} className="col-24-520-down">
            <Button
              type="primary"
              icon={<SettingOutlined />}
              loading={loadings[1]}
              onClick={() => enterLoading(1)}
              block
              danger
            >
              รีเซ็ตตั้งค่าเกมทั้งหมด
            </Button>
          </Col>
          <Col xs={12} className="text-start">
            ตั้งค่าลิงก์เกม
          </Col>
          <Col xs={12} className="text-end">
            <Select
              className="ant-select-dark"
              defaultValue="pgf-asqb7a.com"
              style={{ width: 150 }}
              options={[
                {
                  label: "PG",
                  options: [
                    {
                      label: "pgf-asqb7a.com",
                      value: "pg"
                    }
                  ]
                },
                {
                  label: "Betflik PG",
                  options: [
                    {
                      label: "pgf-thek63.com",
                      value: "bfpg"
                    }
                  ]
                }
              ]}
              disabled
            />
          </Col>
          <Col xs={12} className="text-start">
            ตั้งค่าภาษาในเกม
          </Col>
          <Col xs={12} className="text-end">
            <Select
              labelInValue={true}
              className="ant-select-dark"
              value={inGameLang}
              style={{ width: 150, textAlign: "center" }}
              onChange={handleChangeInGameLang}
              options={[
                {
                  value: "th",
                  label: "ภาษาไทย (TH)"
                },
                {
                  value: "eng",
                  label: "ภาษาอังกฤษ (ENG)"
                }
              ]}
            />
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default ModalMenuSettings;
