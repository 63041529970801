import { API_ENDPOINT, getStorage } from "utils/utility";
import axios from "axios";

const ENDPOINT = {
  AUTH_SIGN_IN: `${API_ENDPOINT}/member/signin`,
  SET_CREDIT: `${API_ENDPOINT}/member/set-credit`,
  GET_PROFILE: `${API_ENDPOINT}/member/get/profile`,
  UPDATE_ACC_NO: `${API_ENDPOINT}/member/change-acc`,
  WITHDRAW: `${API_ENDPOINT}/member/withdraw`,
  UPDATE_IN_GAME_LANG: `${API_ENDPOINT}/member/set-lang`,
  BANK_LIST : `${API_ENDPOINT}/member/bank/list`,
  UPDATE_BANK: `${API_ENDPOINT}/member/set-bank`
};

export const MemberServices = {
  authSignIn: ({ username, password }) => {
    return axios({
      method: "POST",
      url: ENDPOINT.AUTH_SIGN_IN,
      data: { username, password }
    });
  },

  getProfile: () => {
    return axios({
      method: "GET",
      url: ENDPOINT.GET_PROFILE,
      headers: { Authorization: `Bearer ${getStorage("tk")}` }
    });
  },

  setCredit: (nextCredit) => {
    return axios({
      method: "POST",
      url: ENDPOINT.SET_CREDIT,
      headers: { Authorization: `Bearer ${getStorage("tk")}` },
      data: { credit: +nextCredit }
    });
  },

  updateAccountNumber: (bankAccountNo) => {
    return axios({
      method: "PUT",
      url: ENDPOINT.UPDATE_ACC_NO,
      headers: { Authorization: `Bearer ${getStorage("tk")}` },
      data: { acc_no: bankAccountNo }
    });
  },

  withdraw: (reqData) => {
    return axios({
      method: "POST",
      url: ENDPOINT.WITHDRAW,
      headers: { Authorization: `Bearer ${getStorage("tk")}` },
      data: reqData
    });
  },

  updateInGameLanguage: (usLang) => {
    return axios({
      method: "PUT",
      url: ENDPOINT.UPDATE_IN_GAME_LANG,
      headers: { Authorization: `Bearer ${getStorage("tk")}` },
      data: { lang: usLang }
    });
  },

  updateBank: (bankId) => {
    return axios({
      method: "PUT",
      url: ENDPOINT.UPDATE_BANK,
      headers: { Authorization: `Bearer ${getStorage("tk")}` },
      data: { bank_id: bankId }
    });
  },

  bankList: () => {
    return axios({
      method: "GET",
      url: ENDPOINT.BANK_LIST,
      headers: { Authorization: `Bearer ${getStorage("tk")}` }
    });
  }
};

export default MemberServices;
