import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import UILobby from "./views/UILobby";
import UILogin from "./views/UILogin";
import UIGameIframe from "./views/UIGameIframe";
import PrivateRoute from "component/PrivateRoute";
import AnonymousRoute from "component/AnonymousRoute";
import { notification } from "antd";
import { formatDateTime, getDefaultValue } from "utils/utility";

const App = () => {
  const openNotification = (credit, accNo) => {
    let nextAcc = accNo.substring(4, 8);
    let date = formatDateTime(new Date(), "bank");
    notification.open({
      key: "noti",
      message: "รายการเงินเข้า",
      description: `บัญชี xxx-x-x${nextAcc}-x จำนวนเงิน ${getDefaultValue(
        credit
      )} บาท วันที่ ${date} น.`,

      onClick: () => notification.destroy("noti"),
      closeIcon: (
        <div style={{ color: "#af979f", marginTop: "-25px", fontSize: "14px" }}>
          now
        </div>
      ),
      placement: "top",
      duration: 6,
      icon: (
        <img
          src="https://play-lh.googleusercontent.com/AyX675vwNz8X2sYUTSrjUTRVzzXMZUW_nMQ8Vk__Nabj6zQ7s7We-bpZbFmoYf7e2YM=w240-h480-rw"
          style={{ width: "36px", borderRadius: "6px", margin: "12px 0px" }}
        />
      )
    });
  };
  return (
    <Routes>
      <Route element={<AnonymousRoute />}>
        <Route path="/" element={<UILogin />} />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route
          path="/lobby"
          element={<UILobby openNotification={openNotification} />}
        />
        <Route path="/:id/index.html" element={<UIGameIframe />} />
      </Route>
      <Route path="/*" element={<div>404 Not found</div>} />
    </Routes>
  );
};

export default App;
