import React from "react";
import { Button } from "antd";
import { SettingFilled } from "@ant-design/icons";

const CardGameSkeleton = () => {
  return (
    <div className="card-game-skeleton">
      <div
        style={{
          width: "100%",
          minHeight: "125px",
          border: "1px solid white",
          borderRadius: "32px"
        }}
        className="image-skeleton"
      />
      <h2 className="title-game" style={{ height: "15px" }}></h2>
      <Button
        type="primary"
        icon={<SettingFilled />}
        style={{
          width: "100%",
          marginTop: "5px",
          background: "#e9961a",
          borderColor: "white"
        }}
        disabled={true}
      >
        เมนูเพิ่มเติม
      </Button>
    </div>
  );
};

export default CardGameSkeleton;
