export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

// ====== LOCAL STORAGE ======
export const getStorage = (key) => {
  return localStorage.getItem(key);
};

export const setStorage = (key, value) => {
  return localStorage.setItem(key, value);
};
// ===========================

export const getDefaultValue = (value) => {
  let val = parseFloat(value).toFixed(2);
  let temp = val.split(".");
  let itg = parseInt(temp[0]).toLocaleString();
  let digit = temp[1];

  return value ? `${itg}.${digit}` : "0.00";
};

export const getDateRemaining = (exp) => {
  if (!exp) {
    return 0;
  }

  let currentDate = new Date();
  let targetDate = new Date(exp);

  const diff = Math.abs(targetDate - currentDate);
  const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));

  // console.log(diffDays + " days");
  return diffDays;
};

export const sumProbability = (array) => {
  if (!Array.isArray(array)) return "ERR";
  return array.reduce((sum, item) => sum + parseInt(item?.value), 0);
};

// DATE FUNCTION
const timeTwoDigit = (num) => {
  return (num < 10 ? "0" : "") + num;
};

export const formatDateTime = (time, type, packageValue) => {
  let d = new Date(time);
  let h = timeTwoDigit(d.getHours());
  let m = timeTwoDigit(d.getMinutes());
  let s = timeTwoDigit(d.getSeconds());

  if (type === "onlyTime") {
    return `${h}:${m}:${s}`;
  } else if (type === "onlyDate") {
    return `${d.toLocaleDateString("th-TH")}`;
  } else if (type === "future") {
    let currentDate = new Date();
    let value = packageValue * 30;
    const diff = d - currentDate;

    if (diff < 0) {
      let ts = new Date().setDate(currentDate.getDate() + value);
      d = new Date(ts);
    } else {
      let ts = d.setDate(d.getDate() + value);
      d = new Date(ts);
    }
  } else if (type === "bank") {
    let nd = d.toLocaleDateString("th-TH").split("/");
    return `${nd[0]} ${months_th_mini[nd[1] - 1]} ${nd[2].substring(
      2
    )} ${h}:${m}`;
  }
  return `${d.toLocaleDateString("th-TH")} - ${h}:${m}:${s}`;
};

var months_th = [
  "มกราคม",
  "กุมภาพันธ์",
  "มีนาคม",
  "เมษายน",
  "พฤษภาคม",
  "มิถุนายน",
  "กรกฎาคม",
  "สิงหาคม",
  "กันยายน",
  "ตุลาคม",
  "พฤศจิกายน",
  "ธันวาคม"
];
var months_th_mini = [
  "ม.ค.",
  "ก.พ.",
  "มี.ค.",
  "เม.ย.",
  "พ.ค.",
  "มิ.ย.",
  "ก.ค.",
  "ส.ค.",
  "ก.ย.",
  "ต.ค.",
  "พ.ย.",
  "ธ.ค."
];
