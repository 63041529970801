import { API_ENDPOINT, getStorage } from "./utility";
const url = new URL(API_ENDPOINT);

export const SOCKET_OPTION = (auth) => {
  return {
    path: `/socket.io/`,
    transports: ["websocket"],
    query: {
      token: auth?.token || getStorage("tk")
    }
  };
};

export const SOCKET_URI = `${url.protocol}//${url.host}/members`;
