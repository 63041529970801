import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Input,
  Modal,
  Table,
  Tabs,
  Divider,
  Switch,
  Tooltip,
  Collapse,
} from "antd";
import {
  PlusOutlined,
  MinusOutlined,
  InfoCircleFilled,
} from "@ant-design/icons";
import GameServices from "services/GameServices";
import Swal from "sweetalert2";
import { SPIN_TYPE, UPDATE_TYPE } from "context/Constant";
import { sumProbability } from "utils/utility";

const { Panel } = Collapse;

const getMappingSpinName = (name) => {
  switch (name) {
    case "spin":
      return "การหมุน";
    case "free":
      return "ฟรีสปิน";
    default:
      return "ฟีเจอร์บาย";
  }
};

const ModalGameSetting = ({ current, isShow, setIsShowSetting }) => {
  const [configList, setConfigList] = useState({});
  const [controllerMode, setControllerMode] = useState(false);
  const [activeBT, setActiveBT] = useState("");
  const [featureActiveBT, setFeatureActiveBT] = useState("");
  const [countSpinList, setCountSpinList] = useState([]);

  useEffect(() => {
    fetchConfig();
    fetchHotConfig();
    fetchCountSpin();
    return () => {};
  }, []);

  useEffect(() => {
    let configData = {
      game_id: current?.game_id,
      spin_type: activeBT,
    };

    if (current?.feature_buy === "No") {
      if (!activeBT) return;
    } else if (current?.feature_buy === "YES") {
      if (!activeBT || !featureActiveBT) return;
      configData["feature_name_en"] = featureActiveBT;
    }

    GameServices.setHotConfigByGameId(configData);
  }, [activeBT, featureActiveBT]);

  const fetchHotConfig = () => {
    let { game_id } = current;
    GameServices.getHotConfigByGameId(game_id).then(({ data }) => {
      if (data) {
        if (data?.name_en) {
          setActiveBT(data?.name_en);
          setControllerMode(true);
        }
        if (data?.feature_name_en) {
          setFeatureActiveBT(data?.feature_name_en);
          setControllerMode(true);
        }
      }
    });
  };

  const fetchCountSpin = () => {
    let { game_id } = current;
    GameServices.countSpinByGameId(game_id).then(({ data }) => {
      setCountSpinList(data);
    });
  };

  const fetchConfig = () => {
    let { game_id } = current;
    if (game_id) {
      GameServices.getGameConfigById(game_id).then(({ data }) => {
        let temp = {};
        data.map((item, idx) => {
          temp[item?.group] = {
            key: data.length - idx,
            prob: sumProbability(item?.list),
            list: item?.list,
          };
        });
        setConfigList(temp);
      });
    }
  };

  const handleChangeData =
    (index, type) =>
    ({ target: { value } }) => {
      value = value.replace(/\D|\+|-/g, "");
      if (value > 100) {
        return;
      }

      setConfigList((prev) => {
        let currentGroup = prev[type];
        let currentList = [...currentGroup["list"]];
        currentList[index]["value"] = +value || 0;

        return {
          ...prev,
          [type]: {
            ...currentGroup,
            list: currentList,
            prob: sumProbability(currentList),
          },
        };
      });
    };

  const handleUpdateChance = (index, type, medthod) => {
    setConfigList((prev) => {
      let currentGroup = prev[type];
      let currentList = [...currentGroup["list"]];
      let currentValue = currentList[index]["value"];

      if (medthod === UPDATE_TYPE.PLUS) {
        if (currentValue !== 100) {
          currentList[index]["value"] += +1;
        }
      } else if (medthod === UPDATE_TYPE.MINUS) {
        if (currentValue !== 0) {
          currentList[index]["value"] -= 1;
        }
      }

      return {
        ...prev,
        [type]: {
          ...currentGroup,
          list: currentList,
          prob: sumProbability(currentList),
        },
      };
    });
  };

  const handleSubmitData = () => {
    let allConfig = [];
    let probSum = 0;
    let probFeature = 0;
    let hasFeature = false;
    // let probBool = false;

    Object.keys(configList).map((key) => {
      let currentList = configList[key]["list"];
      // if (sumProbability(currentList) <= 0) {
      //   probBool = true;
      // }

      if (key === "feature") {
        probFeature = sumProbability(currentList);
        hasFeature = true;
      } else {
        probSum += sumProbability(currentList);
      }
      allConfig.push(...currentList);
    });

    // if (probBool) {
    //   return Swal.fire(
    //     "ผิดพลาด !",
    //     "โอกาสรวมในแต่ละกลุ่มจะต้องไม่เกิน %",
    //     "warning"
    //   );
    // }

    if (probSum <= 0) {
      return Swal.fire(
        "ผิดพลาด !",
        "การตั้งค่าโอกาสรวมของ 'การหมุน' และ 'ฟรีสปิน' ต้องมากกว่า 0",
        "warning"
      );
    } else if (hasFeature && probFeature <= 0) {
      return Swal.fire(
        "ผิดพลาด !",
        "การตั้งค่าโอกาสรวมของการ 'ซื้อฟีเจอร์' ต้องมากกว่า 0",
        "warning"
      );
    }

    let reqData = {
      game_id: current?.game_id,
      config: allConfig,
    };

    GameServices.updateGameConfig(reqData)
      .then(() => {
        Swal.fire("สำเร็จ", "บันทึกการตั้งค่าเกมสำเร็จ", "success");
        setIsShowSetting(false);
      })
      .catch((err) =>
        Swal.fire("ผิดพลาด !", err?.response?.data?.message, "error")
      );
  };

  const handleSetHotConfig = (spinType) => {
    let nameRegex = /feature/g.test(spinType);
    nameRegex ? setFeatureActiveBT(spinType) : setActiveBT(spinType);
  };

  const handleChangeSwitch = (value) => {
    let gameId = current?.game_id;
    if (!value) {
      GameServices.disabledHotConfigByGameId(gameId).then(() => {
        setActiveBT(null);
        setFeatureActiveBT(null);
      });
    } else {
      if (current?.feature_buy === "No") {
        setActiveBT("spin_dead_spin");
      } else if (current?.feature_buy === "YES") {
        setActiveBT("spin_dead_spin");
        setFeatureActiveBT("feature_buy_normal");
      }
    }
    setControllerMode(value);
  };

  const renderToolTipContent = (key) => {
    if (key == "spin") {
      return <>ไม่รวมการเข้าฟรีสปิน/ฟีเจอร์</>;
    } else if (key == "free") {
      return <>ฟรีสปินและฟีเจอร์ต่างๆ</>;
    } else return <>การซื้อฟีเจอร์/ซื้อฟรีสปิน</>;
  };

  const columns = [
    {
      title: "ชนิดของการหมุน",
      dataIndex: "name_th",
      key: "name_th",
    },
    {
      title: "จำนวนทั้งหมด",
      dataIndex: "count",
      key: "count",
    },
    {
      title: "หมุนไปแล้ว",
      dataIndex: "used",
      key: "used",
      render: (used) => (used ? used : "0"),
    },
  ];

  const dataColumn = (type) => {
    return [
      {
        title: "รายการ",
        key: "name_th",
        dataIndex: "name_th",
      },
      {
        title: () => (
          <span>
            โอกาส
            {/* ({configList[type]["prob"]}%) */}
          </span>
        ),
        key: "value",
        dataIndex: "value",
        align: "center",
        render: (value, { nameEn }, index) => (
          <Row>
            <Col span={5}>
              <Button
                type="primary"
                icon={<MinusOutlined />}
                style={{ background: "#d84f4e", color: "white" }}
                onClick={() =>
                  handleUpdateChance(index, type, UPDATE_TYPE.MINUS)
                }
              />
            </Col>
            <Col span={14}>
              <Input
                style={{ width: "80%", textAlign: "center" }}
                name={nameEn}
                placeholder="กรอกโอกาสในการออก"
                value={parseInt(value)}
                onChange={handleChangeData(index, type)}
              />
            </Col>
            <Col span={5}>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() =>
                  handleUpdateChance(index, type, UPDATE_TYPE.PLUS)
                }
              />
            </Col>
          </Row>
        ),
      },
    ];
  };

  return (
    <Modal
      title={current?.game_name}
      open={isShow}
      onOk={() => setIsShowSetting(false)}
      onCancel={() => setIsShowSetting(false)}
      style={{ minHeight: "600px" }}
      centered={true}
      maskClosable={false}
      className="ant-modal-dark"
      footer={[
        <>
          {!controllerMode && (
            <Button
              style={{ width: "100%", height: "40px", background: "#17975c" }}
              key="submit"
              type="primary"
              onClick={handleSubmitData}
            >
              บันทึกการตั้งค่า
            </Button>
          )}
        </>,
      ]}
    >
      <Divider className="ant-divider-dark" plain>
        โหมดควบคุมเกม
      </Divider>
      <Row>
        <span>การตั้งค่าควบคุมเกม : </span>{" "}
        <Switch
          style={{ marginLeft: "10px" }}
          checked={controllerMode}
          onChange={handleChangeSwitch}
        />
      </Row>
      <div>
        <ul>
          <li>หากเปิดใช้งาน* จะควบคุมการเล่นผ่านตัวเลือกนั้นๆในโอกาส 100% </li>
          <li>หากเปิดใช้งาน* จะปิดการใช้งานการตั้งค่า % ของเกมนั้นๆ</li>
          <li>
            ในกรณีที่เปิดโหมดควบคุมเกมและมีการตั้งค่า % เกม
            โหมดควบคุมเกมจะถูกคิดความสำคัญเป็นอันดับแรก
          </li>
        </ul>
      </div>
      <Divider className="ant-divider-dark" plain>
        {controllerMode ? "ตั้งค่าโหมดควบคุมเกม" : "ตั้งค่าเกม"}
      </Divider>

      {controllerMode ? (
        <>
          {Object.keys(configList).map((key, idx) => {
            let groupTitle = getMappingSpinName(key);
            return (
              <div key={idx}>
                <h4> {groupTitle}</h4>
                <Row gutter={[16, 16]}>
                  {configList[key]["list"].map((item) => {
                    return (
                      <Col lg={8} md={8} xs={12} key={item?._id}>
                        <Button
                          type="primary"
                          style={{
                            width: "100%",
                            textAlign: "center",
                            padding: "2px",
                            backgroundColor:
                              activeBT === item?.name_en ||
                              featureActiveBT === item?.name_en
                                ? "#4caf50"
                                : "#1677ff",
                          }}
                          onClick={() => handleSetHotConfig(item?.name_en)}
                        >
                          {item?.name_th}
                        </Button>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            );
          })}
          <Collapse style={{marginTop:"1rem"}} ghost>
            <Panel header={<span style={{color:"rgba(255,255,255,0.8)"}}>ตารางแสดงจำนวนการหมุน</span>} key="1">
              <Collapse defaultActiveKey="1">
              <Table
                    className={`ant-table-dark`}
                    dataSource={countSpinList}
                    columns={columns}
                    pagination={false}
                    rowKey="_id"
                    // bordered={true}
                  />
              </Collapse>
            </Panel>
          </Collapse>
        </>
      ) : (
        <Tabs
          style={{ marginTop: "15px" }}
          className="ant-tab-dark"
          items={Object.keys(configList).map((key, idx) => {
            let groupTitle = getMappingSpinName(key);
            let groupProbVerify = sumProbability(configList[key]?.list) > 100;

            return {
              label: (
                <span
                  key={idx}
                  // style={{
                  //   color: groupProbVerify ? "red" : null,
                  // }}
                >
                  {/* {groupProbVerify ? `*${name}*` : name} */}
                  {groupTitle}
                  <Tooltip title={renderToolTipContent(key)}>
                    <InfoCircleFilled
                      className="ml-0-5rem"
                      style={{ marginRight: "0px" }}
                    />
                  </Tooltip>
                </span>
              ),
              key: configList[key]["key"],
              children: (
                <Table
                  className="ant-table-dark"
                  style={{ marginBottom: "15px" }}
                  dataSource={configList[key]["list"]}
                  columns={dataColumn(key)}
                  pagination={false}
                  // bordered
                  key="_id"
                />
              ),
            };
          })}
        />
      )}
    </Modal>
  );
};

export default ModalGameSetting;
