import { useState } from "react";
import { getStorage } from "utils/utility";
import jwt_decode from "jwt-decode";

export const usePersistStorage = () => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const token = getStorage("tk");
      if (token) {
        let payload = jwt_decode(token);
        let currentDate = parseInt(Date.now() / 1000);

        if (payload) {
          if (!payload?.exp || currentDate > payload?.exp) {
            sessionStorage.clear();
            return {};
          }
          return { token, ...payload };
        }
      } else {
        return {};
      }
    } catch (err) {
      return {};
    }
  });

  return storedValue;
};
