import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../hook/useAuth";

const AnonymousRoute = () => {
  const { auth } = useAuth();
  const location = useLocation();

  return !auth?.token ? (
    <Outlet />
  ) : (
    <Navigate to="/lobby" state={{ from: location }} replace />
  );
};

export default AnonymousRoute;
