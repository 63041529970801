import { API_ENDPOINT, getStorage } from "utils/utility";
import axios from "axios";

const ENDPOINT = {
  GET_ANNOUNCE: `${API_ENDPOINT}/announcement/client`
};

export const AnnounceService = {
  getAnnouncement: () => {
    return axios({
      method: "GET",
      url: ENDPOINT.GET_ANNOUNCE,
      headers: { Authorization: `Bearer ${getStorage("tk")}` }
    });
  }
};
export default AnnounceService;
