import React from "react";
import { Layout, Row, Col, Form, Input, Button, Avatar } from "antd";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { setStorage } from "utils/utility";
import useAuth from "hook/useAuth";
import MemberServices from "services/MemberServices";
import Swal from "sweetalert2";
import { CommentOutlined } from "@ant-design/icons";
import pgCrackLogo from "assets/images/logo/tpn-rw-pgcrack.png";

let { Content } = Layout;

const UILogin = () => {
  const navigate = useNavigate();
  const { setAuth } = useAuth();

  const onFinish = (data) => {
    if (!data.username || !data?.password) {
      return Swal.fire("ผิดพลาด !", "กรุณากรอกข้อมูลให้ครบถ้วน", "error");
    }

    MemberServices.authSignIn(data)
      .then(({ data }) => {
        let { access_token } = data;
        if (access_token) {
          let payload = jwt_decode(data.access_token);
          setStorage("tk", access_token);
          setStorage("us", payload.username);
          setStorage("uid", payload.userid);
          setStorage("exp", payload.exp);
          setAuth({ token: access_token, ...payload });
          navigate("/lobby");
        }
      })
      .catch(() =>
        Swal.fire("ผิดพลาด !", "กรุณาตรวจสอบชื่อผู้ใช้งานและรหัสผ่าน", "error")
      );
  };

  const onFinishFailed = () => {};

  return (
    <div className="bg-login">
      <Content>
        <Row className="area-login">
          <Col xs={22} md={12} lg={6} className="box-login">
            <div>
              <center>
                <img src={pgCrackLogo} width="100%" height="auto" />
              </center>
              <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                className="row-col"
              >
                <Form.Item
                  style={{ marginBottom: "15px" }}
                  className="username"
                  name="username"
                >
                  <Input
                    placeholder="ชื่อผู้ใช้งาน"
                    style={{ height: "40px" }}
                  />
                </Form.Item>

                <Form.Item className="username" name="password">
                  <Input
                    placeholder="รหัสผ่าน"
                    type="password"
                    style={{ height: "40px" }}
                  />
                </Form.Item>

                {/* <div class="cf-turnstile" data-sitekey="<YOUR_SITE_KEY>"></div> */}

                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: "100%",
                    fontSize: "16px",
                    height: "40px",
                    background: "#2551e3"
                  }}
                >
                  เข้าสู่ระบบ
                </Button>
              </Form>
              <Button
                type="primary"
                htmlType="submit"
                icon={<CommentOutlined style={{ fontSize: "16px" }} />}
                style={{
                  width: "100%",
                  fontSize: "16px",
                  height: "40px",
                  background: "#05ba05",
                  marginTop: "10px",
                  lineHeight: "2"
                }}
              >
                ติดต่อผ่าน Line
              </Button>
            </div>
          </Col>
        </Row>
      </Content>
    </div>
  );
};

export default UILogin;
