import React, { useState, useEffect } from "react";
import { Layout, Row, Col, Button, Card, Input, Avatar } from "antd";
import {
  formatDateTime,
  getDateRemaining,
  getDefaultValue,
  setStorage,
} from "utils/utility";
import { SOCKET_OPTION, SOCKET_URI } from "utils/socketConfig";
import Swal from "sweetalert2";
import {
  EyeFilled,
  DollarCircleOutlined,
  ClockCircleOutlined,
  EditOutlined,
  EyeInvisibleOutlined,
  UpOutlined,
} from "@ant-design/icons";
// SERVICE
import MemberServices from "services/MemberServices";
import GameServices from "services/GameServices";
import Gotop from "react-go-top";

// COMPONENT
import CardGame from "component/CardGame";
import CardGameSkeleton from "component/CardGameSkeleton";
import ModalEditCredit from "component/Modal/ModalCreditManager";
import ModalGameSetting from "component/Modal/ModalGameSetting";
import ModalSettings from "component/Modal/ModalSettings";
import ModalLoggedIn from "component/Modal/ModalLoggedIn";
import { io as socketIOClient } from "socket.io-client";
import { useNavigate } from "react-router-dom";
import useAuth from "hook/useAuth";
import NavBottom from "component/NavBottom";
import ModalHint from "component/Modal/ModalHint";
import ModalAnnou from "component/Modal/ModalAnnouncement";

import pgCrackLogo from "assets/images/logo/tpn-rw-pgcrack.png";
import { MENU_TYPE } from "context/Constant";

const { Content } = Layout;
const { Search } = Input;

const UILobby = ({ openNotification }) => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();
  const [userData, setUserData] = useState({});
  const [bankData, setBankData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isVisibled, setIsVisibled] = useState(false);

  // GAME
  const [gameList, setGameList] = useState([]);
  const [displayGameList, setDisplayGameList] = useState([]);
  const [currentGame, setCurrentGame] = useState();
  const [query, setQuery] = useState("");

  // MODAL CONTROLLER
  const [isShowSetting, setIsShowSetting] = useState(false);
  const [isShowMenuSettings, setIsShowMenuSettings] = useState(false);
  const [isShowMenuHint, setIsShowMenuHint] = useState(false);
  const [isShowMenuAnnou, setIsShowMenuAnnou] = useState(false);
  const [isShowModalCredit, setShowModalCredit] = useState({
    isEdit: false,
    isWithdraw: false,
  });

  useEffect(() => {
    fetchGame();
    fetchProfile();
    fetchBank();
  }, []);

  useEffect(() => {
    if (isShowModalCredit.isWithdraw) {
      fetchProfile();
    }
  }, [isShowModalCredit.isEdit, isShowModalCredit.isWithdraw]);

  useEffect(() => {
    let nextGameList = [...gameList];
    nextGameList = nextGameList.filter((item) => {
      let name = item?.game_name.toLowerCase();
      return name.includes(query.toLowerCase());
    });
    setDisplayGameList(nextGameList);
  }, [query]);

  useEffect(() => {
    const socket = socketIOClient(SOCKET_URI, SOCKET_OPTION(auth));

    socket.on("connect", (res) => {
      // console.log("CONNECTED", res);
    });

    socket.on("credit", (data) => {
      setUserData((prev) => ({ ...prev, credit: data }));
    });

    socket.on("kick", () => {
      handleSignOut();
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const fetchGame = async () => {
    const response = await GameServices.getGameList();
    const { data, status } = response;

    if (status === 200) {
      setDisplayGameList(data);
      setGameList(data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const fetchProfile = async () => {
    const response = await MemberServices.getProfile();
    const { data, status } = response;

    if (status === 200) {
      setUserData(data);
      console.log(data);
      setStorage("igl", data.lang);
    }
  };

  const fetchBank = async () => {
    const response = await MemberServices.bankList();
    const { data, status } = response;

    if (status === 200) {
      setBankData(data);
    }
  };

  const handleSignOut = () => {
    localStorage.clear();
    setAuth(null);
    navigate("/", { replace: true });
  };

  const handleOpenGame = (gameId) => {
    // let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    // GameServices.openGame({
    //   gameId,
    //   redirectUrl: "https://pgcrack.com"
    // })
    //   .then(({ data }) => {
    //     if (data) {
    //       if (isSafari) {
    //         window.open(data, "_self");
    //       } else {
    //         window.open(data, "_blank");
    //       }
    //     } else {
    //       Swal.fire("Oppp !", "NO DATA", "error");
    //     }
    //   })
    //   .catch((err) => Swal.fire("Oppp !", "Something went wrong", "error"));
  };

  const handleShowMenuSettings = () => {
    setIsShowMenuSettings(true);
  };

  const handleshowMenuAnnou = () => {
    setIsShowMenuAnnou(true);
  };

  const handleOpenCreditModal = (type) => {
    if (type === "edit") {
      setShowModalCredit({ isEdit: true, isWithdraw: false });
    } else {
      setShowModalCredit({ isEdit: false, isWithdraw: true });
    }
  };

  const onClickMenu = (event) => {
    let { key } = event;

    switch (key) {
      case MENU_TYPE.SETTING:
        return setIsShowSetting(true);
      case MENU_TYPE.RESET_CONFIG:
        return Swal.fire({
          title: "คำเตือน",
          text: `คุณต้องการที่จะรีเซ็ตการตั้งค่าของ ${currentGame?.game_name} ?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
        }).then((result) => {
          if (result.isConfirmed) {
            GameServices.resetGameConfig(currentGame?.game_id)
              .then(() => {
                Swal.fire(
                  "สำเร็จ",
                  `รีเซ็ตการตั้งค่าเกม ${currentGame?.game_name} สำเร็จ`,
                  "success"
                );
              })
              .catch((err) =>
                Swal.fire("ผิดพลาด !", err?.response?.data?.message, "error")
              );
          }
        });

      case MENU_TYPE.RESET_HISTORY:
        return Swal.fire({
          title: "คำเตือน",
          text: `คุณต้องการที่จะล้างประวัติการเล่นของ ${currentGame?.game_name} ?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
        }).then((result) => {
          if (result.isConfirmed) {
            GameServices.resetGameHistoryByGameId(currentGame?.game_id)
              .then((res) => {
                Swal.fire(
                  "สำเร็จ",
                  `ล้างประวัติการเล่นเกม ${currentGame?.game_name} สำเร็จ`,
                  "success"
                );
              })
              .catch((err) =>
                Swal.fire("ผิดพลาด !", err?.response?.data?.message, "error")
              );
          }
        });
      default:
        break;
    }
  };

  return (
    <div className="lobby-bg pt-2rem-lg">
      <div
        className="lobby-content mt-2rem-lg"
        style={{ marginBottom: "4rem" }}
      >
        <Content
          style={{
            padding: "2rem",
            paddingBottom: "2rem",
            background: "#111111d9",
            borderRadius: "8px",
            border: "1px solid #443e3e",
          }}
        >
          <Card className="profile-card">
            <Row>
              <Col xs={24} style={{ padding: "5px 10px" }}>
                <Row gutter={[20, 20]} style={{ marginBottom: "20px" }}>
                  <Col flex="60px">
                    <Avatar
                      shape="square"
                      size={60}
                      icon={<img src={pgCrackLogo} />}
                    />
                  </Col>
                  <Col flex="auto">
                    <div>
                      <span style={{ fontSize: "18px" }}>ยินดีต้อนรับ</span>
                      <br />
                      <span style={{ fontSize: "16px" }}>
                        คุณ {userData?.user_agent}
                      </span>
                    </div>
                  </Col>
                  <Col flex="130px">
                    <Button
                      type="link"
                      style={{ color: "#ffc716" }}
                      onClick={() => setIsVisibled((prev) => !prev)}
                    >
                      {isVisibled ? (
                        <>
                          <EyeInvisibleOutlined /> ซ่อนเมนูเพิ่มเติม
                        </>
                      ) : (
                        <>
                          <EyeFilled /> แสดงเมนูเพิ่มเติม
                        </>
                      )}
                    </Button>
                  </Col>
                </Row>
                <Row gutter={[20, 20]}>
                  <Col xs={12} className="w-100 col-24-520-down">
                    <Row
                      gutter={[10, 10]}
                      className="profile-desc"
                      style={{ height: "75px" }}
                    >
                      <Col xs={4} style={{ alignSelf: "center" }}>
                        <DollarCircleOutlined style={{ fontSize: "30px" }} />
                      </Col>
                      <Col xs={20}>
                        <span
                          className="text-light-gray"
                          style={{ fontSize: "14px" }}
                        >
                          เครดิตคงเหลือ
                        </span>
                        <br />
                        <span style={{ fontSize: "16px" }}>
                          ฿{getDefaultValue(userData?.credit)}{" "}
                          {isVisibled && (
                            <>
                              <Button
                                type="link"
                                className="p-0"
                                style={{ color: "#ffc716" }}
                                onClick={() => handleOpenCreditModal("edit")}
                              >
                                <EditOutlined />
                                แก้ไข
                              </Button>
                            </>
                          )}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} className="w-100 col-24-520-down">
                    <Row
                      gutter={[10, 10]}
                      className="profile-desc"
                      style={{ height: "75px" }}
                    >
                      <Col xs={4} style={{ alignSelf: "center" }}>
                        <ClockCircleOutlined style={{ fontSize: "30px" }} />
                      </Col>
                      <Col xs={20}>
                        <span
                          className="text-light-gray"
                          style={{ fontSize: "14px" }}
                        >
                          วันหมดอายุ (
                          {formatDateTime(userData?.expire, "onlyDate")})
                        </span>
                        <br />
                        <span style={{ fontSize: "16px" }}>
                          {getDateRemaining(userData?.expire)} วัน
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>

          <Row style={{ margin: "16px 0px" }}>
            <Search
              className="game-serach-bar ant-input-dark"
              style={{ width: "100%" }}
              placeholder="ค้นหาชื่อเกม"
              allowClear
              onChange={({ target: { value } }) => setQuery(value)}
            />
          </Row>

          <Row gutter={[16, 16]}>
            {!isLoading &&
              displayGameList.length > 0 &&
              displayGameList.map((item, index) => {
                return (
                  <Col
                    lg={6}
                    md={6}
                    sm={8}
                    xs={12}
                    key={index}
                    style={{ textAlign: "center" }}
                  >
                    <CardGame
                      item={item}
                      openGame={handleOpenGame}
                      onClickMenu={onClickMenu}
                      setCurrentGame={setCurrentGame}
                      isVisibled={isVisibled}
                    />
                  </Col>
                );
              })}

            {isLoading &&
              Array.from({ length: 8 }).map((_, index) => {
                return (
                  <Col
                    lg={6}
                    md={6}
                    sm={8}
                    xs={12}
                    key={index}
                    style={{ textAlign: "center" }}
                  >
                    <CardGameSkeleton isVisibled={isVisibled} />
                  </Col>
                );
              })}
          </Row>
        </Content>
      </div>

      <NavBottom
        handleSignOut={handleSignOut}
        handleShowMenuSettings={handleShowMenuSettings}
        handleshowMenuAnnou={handleshowMenuAnnou}
        handleOpenCreditModal={handleOpenCreditModal}
      />

      {isShowSetting && (
        <ModalGameSetting
          isShow={isShowSetting}
          setIsShowSetting={setIsShowSetting}
          current={currentGame}
        />
      )}

      {(isShowModalCredit?.isEdit || isShowModalCredit?.isWithdraw) && (
        <ModalEditCredit
          show={isShowModalCredit}
          setShowModalCredit={setShowModalCredit}
          openNotification={openNotification}
          userData={userData}
          bankData={bankData}
        />
      )}

      {isShowMenuAnnou && (
        <ModalAnnou
          isShow={isShowMenuAnnou}
          setIsShowMenuAnnou={setIsShowMenuAnnou}
        />
      )}

      {/* {isShowMenuHint && (
        <ModalHint
          isShow={isShowMenuHint}
          setIsShowMenuHint={setIsShowMenuHint}
        />
      )} */}

      {isShowMenuSettings && (
        <ModalSettings
          isShow={isShowMenuSettings}
          setIsShowMenuSettings={setIsShowMenuSettings}
          usLang={userData?.lang}
        />
      )}

      <Gotop
        style={{ width: "55px", height: "55px", background: "#FF5151" }}
        visibilityHeight={1500}
        target={() => document.getElementById("app")}
      >
        <UpOutlined />
      </Gotop>

      {/* <ModalLoggedIn /> */}
    </div>
  );
};

export default UILobby;
