import { API_ENDPOINT, getStorage } from "utils/utility";
import axios from "axios";

const ENDPOINT = {
  GET_GAME_LIST: `${API_ENDPOINT}/game/game-list`,
  OPEN_GAME: `${API_ENDPOINT}/game/generate-url`,
  COUNT_SPIN: `${API_ENDPOINT}/game/count-used-spin`,
  SWITCH_GAME: `${API_ENDPOINT}/game/switch-game`,

  CONFIG: {
    GET_BY_GAME_ID: (gameId) => `${API_ENDPOINT}/game/config/${gameId}`,
    UPDATE: `${API_ENDPOINT}/game/config`,
    RESET_BY_GAME_ID: `${API_ENDPOINT}/game/clear-config-game-id`,
    RESET_ALL: `${API_ENDPOINT}/game/clear-game-config`,
  },

  HOT_CONFIG: {
    GET_BY_GAME_ID: `${API_ENDPOINT}/game/get-hot-config`,
    SET_BY_GAME_ID: `${API_ENDPOINT}/game/hot-config`,
    DISABLED_BY_GAME_ID: `${API_ENDPOINT}/game/clear-hot-config`,
  },

  GAME_HISTORY: {
    RESET_BY_GAME_ID: `${API_ENDPOINT}/game/clear-history-game`,
    RESET_ALL: `${API_ENDPOINT}/game/clear-history-all`,
  },
};

export const GameServices = {
  getGameList: () => {
    return axios({
      method: "GET",
      url: ENDPOINT.GET_GAME_LIST,
      headers: { Authorization: `Bearer ${getStorage("tk")}` },
    });
  },

  openGame: (reqData) => {
    return axios({
      method: "POST",
      url: ENDPOINT.OPEN_GAME,
      headers: { Authorization: `Bearer ${getStorage("tk")}` },
      data: { game_id: reqData.gameId, redirect_url: reqData.redirectUrl },
    });
  },

  switchGame: (reqData) => {
    return axios({
      method: "POST",
      url: ENDPOINT.SWITCH_GAME,
      headers: { Authorization: `Bearer ${getStorage("tk")}` },
      data: { game_id: reqData.gameId, redirect_url: reqData.redirectUrl },
    });
  },

  // ==== GAME CONFIG ====
  getGameConfigById: (gameId) => {
    return axios({
      method: "GET",
      url: ENDPOINT.CONFIG.GET_BY_GAME_ID(gameId),
      headers: { Authorization: `Bearer ${getStorage("tk")}` },
    });
  },

  updateGameConfig: (reqData) => {
    return axios({
      method: "POST",
      url: ENDPOINT.CONFIG.UPDATE,
      headers: { Authorization: `Bearer ${getStorage("tk")}` },
      data: reqData,
    });
  },

  resetAllGameConfig: () => {
    return axios({
      method: "POST",
      url: ENDPOINT.CONFIG.RESET_ALL,
      headers: { Authorization: `Bearer ${getStorage("tk")}` },
    });
  },

  resetGameConfig: (gameId) => {
    return axios({
      method: "POST",
      url: ENDPOINT.CONFIG.RESET_BY_GAME_ID,
      headers: { Authorization: `Bearer ${getStorage("tk")}` },
      data: { game_id: gameId },
    });
  },
  // ==== END GAME CONFIG ====

  // ==== GAME HISTORY ====
  resetGameHistoryByGameId: (reqData) => {
    return axios({
      method: "POST",
      url: ENDPOINT.GAME_HISTORY.RESET_BY_GAME_ID,
      headers: { Authorization: `Bearer ${getStorage("tk")}` },
      data: { game_id: reqData },
    });
  },

  resetAllGameHistory: () => {
    return axios({
      method: "POST",
      url: ENDPOINT.GAME_HISTORY.RESET_ALL,
      headers: { Authorization: `Bearer ${getStorage("tk")}` },
    });
  },
  // ==== END GAME HISTORY ====

  // ==== HOT CONFIG ====
  getHotConfigByGameId: (gameId) => {
    return axios({
      method: "POST",
      url: ENDPOINT.HOT_CONFIG.GET_BY_GAME_ID,
      headers: { Authorization: `Bearer ${getStorage("tk")}` },
      data: { game_id: gameId },
    });
  },

  setHotConfigByGameId: (configData) => {
    return axios({
      method: "POST",
      url: ENDPOINT.HOT_CONFIG.SET_BY_GAME_ID,
      headers: { Authorization: `Bearer ${getStorage("tk")}` },
      data: configData,
    });
  },

  disabledHotConfigByGameId: (gameId) => {
    return axios({
      method: "POST",
      url: ENDPOINT.HOT_CONFIG.DISABLED_BY_GAME_ID,
      headers: { Authorization: `Bearer ${getStorage("tk")}` },
      data: { game_id: gameId },
    });
  },
  // ==== END HOT CONFIG ====
  countSpinByGameId: (gameId) => {
    return axios({
      method: "POST",
      url: ENDPOINT.COUNT_SPIN,
      headers: { Authorization: `Bearer ${getStorage("tk")}` },
      data: { game_id: gameId }
    });
  }
  
};

export default GameServices;
