import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faDonate,
  faHandHoldingUsd,
  faDiceSix,
  faGear,
  faRightFromBracket,
  faCircleInfo,
  faBullhorn
} from "@fortawesome/free-solid-svg-icons";
import { faLine, faMegaport } from "@fortawesome/free-brands-svg-icons";
import { Col, Row } from "antd";

const NavItem = ({ toLink, icon, title, children, target, onClick }) => {
  return (
    <>
      {onClick ? (
        <li className="nav_item">
          <button onClick={onClick}>
            <FontAwesomeIcon icon={icon} className="fs-25px-to-18px" />
            <span className="mt-10px-mobile">{title}</span>
          </button>
        </li>
      ) : (
        <>
          {target ? (
            <a href={toLink} target={target}>
              <li className="nav_item">
                {icon && (
                  <FontAwesomeIcon icon={icon} className="fs-25px-to-18px" />
                )}
                <span className="mt-10px-mobile">{title}</span>
              </li>
            </a>
          ) : (
            <Link to={toLink}>
              <li className="nav_item">
                {icon && (
                  <FontAwesomeIcon icon={icon} className="fs-25px-to-18px" />
                )}
                {children ? (
                  children
                ) : (
                  <span className="mt-10px-mobile">{title}</span>
                )}
              </li>
            </Link>
          )}
        </>
      )}
    </>
  );
};

const NavBottom = ({
  handleSignOut,
  handleShowMenuSettings,
  handleOpenCreditModal,
  handleshowMenuAnnou
}) => {
  return (
    <Row>
      <Col span={24} style={{ textAlign: "center", fontSize: "16px" }}>
        <div className="fixnav_bottom" style={{ backgroundColor: "#181818" }}>
          <Row justify="center">
            <Col xs={24} xl={11}>
              <ul className="fixnav">
                <NavItem
                  onClick={handleshowMenuAnnou}
                  icon={faBullhorn}
                  title="ประกาศ"
                  style={{ fontSize: "18px" }}
                />

                <NavItem
                  onClick={() => handleOpenCreditModal("withdraw")}
                  icon={faDonate}
                  title="แจ้งถอนเงิน"
                />

                {/* <NavItem
                  onClick={handleshowMenuHint}
                  icon={faCircleInfo}
                  title="วิธีใช้งาน"
                /> */}

                <NavItem
                  onClick={handleShowMenuSettings}
                  icon={faGear}
                  title="ตั้งค่าระบบ"
                />

                <NavItem
                  onClick={handleSignOut}
                  icon={faRightFromBracket}
                  title="ล็อกเอาท์"
                />
              </ul>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default NavBottom;
